import { ReactNode, useEffect, useState } from 'react';

interface MapLoaderProps {
  children: ReactNode;
}

export function MapLoader({ children }: MapLoaderProps) {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    const checkIfLoaded = () => {
      if (window.google && google.maps?.geometry?.spherical.computeOffset) {
        setIsLoaded(true);
      }
    };

    // Check immediately if the Google Maps script is already loaded
    checkIfLoaded();

    // Add an event listener to handle cases where the script loads after component mounts
    const interval = setInterval(() => {
      checkIfLoaded();
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return <>{isLoaded ? children : <p>Loading Libraries...</p>}</>;
}
