/* eslint-disable complexity */
import { features } from 'process';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  IconButton,
} from '@chakra-ui/react';
import { CSSProperties, useContext, useEffect, useRef, useState } from 'react';
import { Point } from 'geojson';
import { useScreenDimensions } from '../shared/hook/useScreenDimensions.js';
import { MyIcon } from '../shared/component/Icons.js';
import PlanIcon from '../asset/icons/PlanIcon.js';
import MapIcon from '../asset/icons/MapIcon.js';
import ListIcon from '../asset/icons/ListIcon.js';
import { LmmImage, LmmJobResponse, LmmRoad } from '../shared/entity.js';
import { capitalizeEachWord, formatAsCurrencyAlt, getYearDate } from '../utils/stringUtils.js';
import { GlobalContext } from '../context/GlobalContext.js';
import {
  expandBoundsByDistance,
  extractCoordinates,
  getClosestPointOnPath,
  isBetweenTwoPointsAlongAPath,
  stringToLatLng,
} from '../map/mapHelper.js';
import { useMapRecenter } from '../shared/hook/useMapRecenter.js';
import { APP_COLORS } from '../utils/constants.js';
import { JobCategory, JobStatus } from '../shared/const.js';
import { CreateJobPanel } from '../map/modals/CreateJobPanel.js';
import { convertLatLngArrayToLineString, reorderLatLngByPath } from '../utils/mapUtils.js';
import DISPATCH_ACTIONS from '../context/actions.js';
import { buildLmmImageMarker } from '../map/mapMarkers.js';
import { StatusTag } from './StatusTag.js';
import PlanJobRow from './components/PlanJobRow.js';

interface PolylineType {
  id: string;
  polyline: google.maps.Polyline | null;
}
export default function PlanMapView({
  maintenanceJobs,
  backLogJobs,
  refetchJobs,
  onSwitchToListView,
}: {
  maintenanceJobs: LmmJobResponse[];
  backLogJobs: LmmJobResponse[];
  refetchJobs: () => void;
  onSwitchToListView: () => void;
}) {
  const { height } = useScreenDimensions();
  const { state, dispatch } = useContext(GlobalContext);
  const mapElRef = useRef<HTMLDivElement | null>(null); // Initialize with null
  const mapRef = useRef<google.maps.Map | null>(null); // Initialize with null
  const [mapIsSet, setMapIsSet] = useState<boolean>(false);
  const [isShrunk, setIsShrunk] = useState<boolean>(false);
  const [openCreateJob, setOpenCreateJob] = useState<boolean>(false);
  const polylinesRef = useRef<PolylineType[]>(null);
  const previousPolylinesRef = useRef<PolylineType[]>(null);
  const markersRef = useRef<readonly google.maps.marker.AdvancedMarkerElement[]>([]);
  const [selectedLmmImageId, setSelectedLmmImageId] = useState<string>();
  const [previousRoadToSelectMilePost, setPreviousRoadToSelectMilePost] = useState<LmmRoad>();

  const startMarkerRef = useRef<google.maps.marker.AdvancedMarkerElement | null>(null);
  const stopMarkerRef = useRef<google.maps.marker.AdvancedMarkerElement | null>(null);

  const recenter = useMapRecenter({ mapRef });

  useEffect(() => {
    const initializeMap = async () => {
      try {
        const { Map } = (await google.maps.importLibrary('maps')) as google.maps.MapsLibrary;

        if (mapElRef.current) {
          mapRef.current = new Map(mapElRef.current, {
            center: { lat: 37.7749, lng: -122.4194 },
            zoom: 8,
            mapId: 'map',
            mapTypeControlOptions: {
              position: google.maps.ControlPosition.BOTTOM_LEFT,
            },
            noClear: true,
            fullscreenControl: false,
          });

          setMapIsSet(true);
        }
      } catch (error) {
        console.error('Error initializing map:', error);
      }
    };

    initializeMap();
  }, []);

  const getRoadsWithJobs = (jobs: LmmJobResponse[], roads: LmmRoad[]) => {
    const output = [];

    for (const job of jobs) {
      const jobRoad = roads.find((x) => x.id == job.roadId);
      output.push({
        startingMilepost: stringToLatLng(job?.startingMilepost),
        endingMilepost: stringToLatLng(job.endingMilepost),
        ...jobRoad,
        jobId: job.id,
      });
    }

    return output;
  };

  useEffect(() => {
    if (state.lmmRoads.length && mapIsSet) {
      const roadsWithJobs = getRoadsWithJobs([...maintenanceJobs, ...backLogJobs], state.lmmRoads);

      const bounds = new google.maps.LatLngBounds();
      const featCollection: ({
        type: 'Feature';
      } & google.maps.Data.FeatureOptions)[] = [];
      const polylines: PolylineType[] = [];
      roadsWithJobs.forEach((road) => {
        const roadPath = (road.geometry as unknown as Point).coordinates.map(
          (coord: any) => new google.maps.LatLng(coord[1], coord[0])
        );
        let latLngLiteralArray: google.maps.LatLng[] = [];
        if (road.startingMilepost) {
          latLngLiteralArray = roadPath.filter((x) =>
            isBetweenTwoPointsAlongAPath(x, road.startingMilepost, road.endingMilepost, roadPath)
          );
          latLngLiteralArray = [road.startingMilepost, ...latLngLiteralArray, road.endingMilepost];
        } else {
          latLngLiteralArray = roadPath;
        }

        const job = [...maintenanceJobs, ...backLogJobs].find((x) => x.id == road.jobId);
        let lineColor = APP_COLORS.gray;
        switch (job.status) {
          case JobStatus.completed:
            lineColor = APP_COLORS.green;
            break;
          case JobStatus.in_progress:
            lineColor = APP_COLORS.yellow;
            break;
          case JobStatus.delayed:
            lineColor = APP_COLORS.red;
            break;
          default:
            lineColor = APP_COLORS.gray;
            break;
        }

        // const feature = {
        //   type: 'Feature',
        //   id: job.id,
        //   properties: {
        //     data: road,
        //   },
        //   geometry: convertLatLngArrayToLineString(latLngLiteralArray),
        // } as {
        //   type: 'Feature';
        // } & google.maps.Data.FeatureOptions;
        // featCollection.push(feature);

        // mapRef.current.data.addGeoJson({ type: 'FeatureCollection', features: featCollection });
        const newPolyline = new google.maps.Polyline({
          path: latLngLiteralArray,
          geodesic: true,
          strokeColor: lineColor,
          strokeOpacity: 1.0,
          strokeWeight: 6,
        });

        // Add the polyline to the map
        newPolyline.setMap(mapRef.current);
        polylines.push({ id: road.id, polyline: newPolyline });
        latLngLiteralArray.forEach((point) => bounds.extend(point));
      });
      polylinesRef.current = polylines;

      const expandedBounds = expandBoundsByDistance(bounds, 1000);
      mapRef.current.fitBounds(expandedBounds);
    }
  }, [maintenanceJobs, backLogJobs, state.lmmRoads, recenter, mapIsSet]);

  // redo
  /**
   * Helper function to create marker content with custom text and color.
   */
  const createMarkerContent = (text: string, color: string) => {
    const markerDiv = document.createElement('div');
    markerDiv.style.backgroundColor = color;
    markerDiv.style.borderRadius = '50%';
    markerDiv.style.width = '25px';
    markerDiv.style.height = '25px';
    markerDiv.style.display = 'flex';
    markerDiv.style.justifyContent = 'center';
    markerDiv.style.alignItems = 'center';
    markerDiv.style.color = 'white';
    markerDiv.style.fontWeight = 'bold';
    markerDiv.style.fontSize = '8px';
    markerDiv.innerText = text;
    return markerDiv;
  };

  const loadRoadMilePost = (road: LmmRoad) => {
    const roadCordArray = (road.geometry as unknown as Point).coordinates.map(
      (coord: any) => new google.maps.LatLng(coord[1], coord[0])
    );
    const roadImages = state.lmmImages.filter((x) => x.roadId == state.roadToSelectMilePost.id);
    roadImages.forEach((image) => {
      const marker = markersRef.current.find((x) => x.id == image.id);
      if (marker) {
        marker.map = mapRef.current;
      }
    });

    const latLngLiteralArray = reorderLatLngByPath(roadCordArray);

    const latLngLiteralArrayLength = latLngLiteralArray.length;

    dispatch({
      type: DISPATCH_ACTIONS.SET_MILEPOST,
      payload: {
        start: state.startEndMilePost.start || latLngLiteralArray[0],
        end: state.startEndMilePost.end || latLngLiteralArray[latLngLiteralArrayLength - 1],
      },
    });
    const coordinates = extractCoordinates(latLngLiteralArray);

    const startMarker = new google.maps.marker.AdvancedMarkerElement({
      position: state.startEndMilePost.start || latLngLiteralArray[0],
      map: mapRef.current,
      gmpDraggable: true,
      content: createMarkerContent('Start', APP_COLORS.green), // Green Start marker
    });
    startMarkerRef.current = startMarker;

    // Create AdvancedMarkerElement for the  points
    const endMarker = new google.maps.marker.AdvancedMarkerElement({
      position: state.startEndMilePost.end || latLngLiteralArray[latLngLiteralArrayLength - 1],
      map: mapRef.current,
      gmpDraggable: true,
      content: createMarkerContent('Stop', APP_COLORS.red), // Red Stop marker
    });
    stopMarkerRef.current = endMarker;

    // Add event listeners
    startMarker.addListener('dragend', (event: google.maps.MapMouseEvent) => {
      const snappedPosition = getClosestPointOnPath(event.latLng, latLngLiteralArray);
      startMarker.position = snappedPosition;
      dispatch({ type: DISPATCH_ACTIONS.SET_MILEPOST, payload: { start: snappedPosition } });
      blurImagePointsNotWithin(startMarker.position, endMarker.position as google.maps.LatLng, latLngLiteralArray);
    });

    endMarker.addListener('dragend', (event: google.maps.MapMouseEvent) => {
      const snappedPosition = getClosestPointOnPath(event.latLng, latLngLiteralArray);
      endMarker.position = snappedPosition;
      dispatch({ type: DISPATCH_ACTIONS.SET_MILEPOST, payload: { end: snappedPosition } });
      blurImagePointsNotWithin(startMarker.position as google.maps.LatLng, endMarker.position, latLngLiteralArray);
    });

    recenter(coordinates, 18);
  };

  const loadViewForMilePostSelection = (road: LmmRoad) => {
    const selectedPolylines = polylinesRef.current?.filter((x) => x.id == road.id) || [];

    for (const data of selectedPolylines) {
      data.polyline.setMap(null);
    }
    loadRoadMilePost(road);
  };

  const revertViewForMilePostSelection = () => {
    const selectedPolylines = polylinesRef.current.filter((x) => x.id == previousRoadToSelectMilePost?.id);

    for (const data of selectedPolylines) {
      data.polyline.setMap(mapRef.current);
    }
    if (startMarkerRef.current) {
      startMarkerRef.current.map = undefined;
    }
    if (stopMarkerRef.current) {
      stopMarkerRef.current.map = undefined;
    }
    if (polylinesRef.current) {
      markersRef.current.forEach((marker) => {
        marker.map = undefined;
      });
    }
  };

  const blurImagePointsNotWithin = (
    start: google.maps.LatLng,
    end: google.maps.LatLng,
    polylinePath: google.maps.LatLng[]
  ) => {
    if (state.roadToSelectMilePost) {
      const roadImages = state.lmmImages.filter((x) => x.roadId == state.roadToSelectMilePost.id);
      const imagesToBlur: LmmImage[] = [];
      for (const image of roadImages) {
        const blurImage = !isBetweenTwoPointsAlongAPath(
          new google.maps.LatLng(image.position.coordinates[0], image.position.coordinates[1]),
          start,
          end,
          polylinePath
        );
        if (blurImage) {
          imagesToBlur.push(image);
        }
      }
      markersRef.current.forEach((marker) => {
        const isExist = imagesToBlur.find((x) => x.id == marker.id);
        if (isExist) {
          marker.content = buildLmmImageMarker(isExist, null, 0.3);
        } else {
          const isRoadImage = roadImages.find((x) => x.id == marker.id);
          if (isRoadImage) {
            marker.content = buildLmmImageMarker(isRoadImage, null, 1);
          }
        }
      });
    }
  };
  useEffect(() => {
    if (state.roadToSelectMilePost) {
      setPreviousRoadToSelectMilePost(state.roadToSelectMilePost);
      loadViewForMilePostSelection(state.roadToSelectMilePost);
    } else if (polylinesRef.current) {
      // polyline.setMap(mapRef.current);
      revertViewForMilePostSelection();
      // setReRun(!rerun);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.roadToSelectMilePost]);

  useEffect(() => {
    const allMarkers = state.lmmImages.map((img) => {
      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: {
          lat: img.position.coordinates[0],
          lng: img.position.coordinates[1],
        },
        content: buildLmmImageMarker(img, selectedLmmImageId),
      });

      marker.id = img.id;
      marker.dataset.roadId = img.roadId;
      marker.dataset.sectionId = img.sectionId;
      marker.dataset.pci = `${img.pci}`;
      // marker.addListener('click', () => {
      //   // if (!state.isCreatingJob) {
      //   setSelectedLmmImageId(img.id);
      //   const road = roadsRef.find((x) => x.id == img.roadId);
      //   setSelectedRoad(road);
      //   // }
      // });

      return marker;
    });

    markersRef.current = allMarkers;
    // autoCenter(allMarkers);
    // if (!selectedLmmImageId) {
    // }
  }, []);

  const onChangeRoad = (road: LmmRoad) => {
    dispatch({ type: DISPATCH_ACTIONS.SET_MILEPOST, payload: { start: null, end: null } });
    revertViewForMilePostSelection();
    dispatch({ type: DISPATCH_ACTIONS.SET_ROAD_TO_SELECT_MILEPOST, payload: road });
  };

  return (
    <div style={containerStyle}>
      <div ref={mapElRef} style={mapStyle} className="map"></div>
      {openCreateJob && (
        <CreateJobPanel
          onCreateSuccess={refetchJobs}
          onClose={() => setOpenCreateJob(false)}
          onChangeRoad={onChangeRoad}
          onSelectMilePost={setIsShrunk}
        />
      )}
      <Box
        bg="var(--bg-color)"
        sx={{
          maxHeight: `${height - 130}px`,
          width: '33%',
          padding: 'var(--rem-22px) var(--rem-32px) var(--rem-10px)',
          ...styles.container,
        }}
      >
        <>
          <Box sx={styles.sideBox}>
            <p style={{ ...styles.leftText, marginLeft: '0px' }}>Plan</p>
            <Box sx={styles.iconBox}>
              <Box sx={{ backgroundColor: '#2C2C2C', border: '1.5px solid #383839', borderRadius: '8px' }}>
                <IconButton
                  title="List View"
                  aria-label="List View"
                  icon={<ListIcon width={20} height={20} color="var(--border-color-lighter)" />}
                  size="lg"
                  color="none"
                  background="none"
                  height="var(--rem-44px)"
                  width="var(--rem-44px)"
                  onClick={onSwitchToListView}
                />
                <IconButton
                  title="Map View"
                  aria-label="Map View"
                  icon={<MapIcon />}
                  size="lg"
                  colorScheme="customBlue"
                  height="var(--rem-44px)"
                  width="var(--rem-44px)"
                />
              </Box>
            </Box>
          </Box>
          <Box sx={{ ...styles.sideBox, marginTop: 'var(--rem-24px)' }}>
            <PlanIcon />
            <p style={{ ...styles.leftText, fontSize: 'var(--rem-20px)' }}>All Plans</p>
            <Button
              color="white"
              leftIcon={<MyIcon icon="add" />}
              colorScheme="customBlue"
              sx={{ marginLeft: 'auto' }}
              onClick={() => setOpenCreateJob(true)}
              isDisabled={state.isEditingPlanMapViewJob}
            >
              Create Job
            </Button>
          </Box>

          <Accordion defaultIndex={[0, 1]} allowMultiple p="0px" mt="var(--rem-16px)">
            <AccordionItem
              border="none"
              sx={{
                backgroundColor: '#2C2C2C',
                border: '1px solid #383839',
                borderRadius: 'var(--rem-12px)',
                p: 'var(--rem-16px)',
              }}
            >
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                <AccordionButton p="0px" m="0px" width="80%">
                  <AccordionIcon color="var(--border-color-lighter)" marginRight="16px" />
                  <p style={{ fontSize: 'var(--rem-18px)', fontWeight: '700', lineHeight: 'var(--rem-24px)' }}>
                    Maintenance Plan
                  </p>
                  <Box
                    sx={{
                      color: '#3888FF',
                      backgroundColor: 'rgba(56, 136, 255, 0.15)',
                      fontSize: '14px',
                      fontWeight: 500,
                      padding: '4px 12px',
                      borderRadius: '50px',
                      marginLeft: '16px',
                    }}
                  >
                    {`${maintenanceJobs?.length} Job${maintenanceJobs?.length > 1 ? 's' : ''}`}
                  </Box>
                </AccordionButton>
              </Box>
              {maintenanceJobs.length == 0 && (
                <Box backgroundColor="var(--separator-color)" height="1px" width="100%" mt="var(--rem-8px)"></Box>
              )}

              <AccordionPanel p={0} mt="var(--rem-16px)">
                {maintenanceJobs.map((job, index) => (
                  <PlanJobRow
                    refetchJobs={refetchJobs}
                    key={index}
                    job={job}
                    isFirst={index == 0}
                    isLast={index == maintenanceJobs.length - 1}
                    category={JobCategory.maintenance}
                  />
                ))}
                {/* {state.currentRoadJobs.length == 0 && (
                    <Box display="flex" justifyContent="center" mt="15px">
                      <i style={{ color: 'var(--Color-Lighter-Grey)' }}>No job has been created on this road</i> 
                    </Box>
                  )} */}
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem
              border="none"
              sx={{
                backgroundColor: '#2C2C2C',
                border: '1px solid #383839',
                borderRadius: 'var(--rem-12px)',
                p: 'var(--rem-16px)',
                mt: 'var(--rem-16px)',
              }}
            >
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                <AccordionButton p="0px" m="0px" width="80%">
                  <AccordionIcon color="var(--border-color-lighter)" marginRight="16px" />
                  <p style={{ fontSize: 'var(--rem-18px)', fontWeight: '700', lineHeight: 'var(--rem-24px)' }}>
                    Backlog
                  </p>
                  <Box
                    sx={{
                      color: '#3888FF',
                      backgroundColor: 'rgba(56, 136, 255, 0.15)',
                      fontSize: '14px',
                      fontWeight: 500,
                      padding: '4px 12px',
                      borderRadius: '50px',
                      marginLeft: '16px',
                    }}
                  >
                    {`${backLogJobs?.length} Job${backLogJobs?.length > 1 ? 's' : ''}`}
                  </Box>
                </AccordionButton>
              </Box>
              {backLogJobs.length == 0 && (
                <Box backgroundColor="var(--separator-color)" height="1px" width="100%" mt="var(--rem-8px)"></Box>
              )}

              <AccordionPanel p={0} mt="var(--rem-16px)">
                {backLogJobs.map((job, index) => (
                  <PlanJobRow
                    key={index}
                    job={job}
                    isFirst={index == 0}
                    isLast={index == maintenanceJobs.length - 1}
                    category={JobCategory.backlog}
                  />
                ))}
                {/* {state.currentRoadJobs.length == 0 && (
                    <Box display="flex" justifyContent="center" mt="15px">
                      <i style={{ color: 'var(--Color-Lighter-Grey)' }}>No job has been created on this road</i> 
                    </Box>
                  )} */}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </>
      </Box>
    </div>
  );
}

const mapStyle: CSSProperties = {
  width: '100vw',
  height: '100vh',
};

const styles = {
  container: {
    top: 'var(--rem-112px)',
    marginLeft: '0px',
    marginRight: 'var(---view-padding-m)',
    position: 'fixed',
    right: 0,
    borderRadius: 'var(--rem-24px)',
    transition: 'width 0.5s ease',
    overflowY: 'scroll',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
  },
  roadName: {
    fontSize: 'var(--rem-20px)',
    fontWeight: '700',
    lineHeight: 'var(--rem-28px)',
    color: '#fff',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  labelContainer: { width: '50%', marginBottom: 'var(--rem-8px)' },
  infoLabel: { color: 'var(--Color-Lighter-Grey)', fontSize: 'var(--rem-14px)' },
  infoValue: { color: '#fff', fontWeight: '500', marginLeft: 'var(--rem-7px)' },
  tableHead: { borderWidth: '0px' },
  tableRow: {
    fontSize: '14px',
    borderWidth: '0px',
  },
  sideBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  leftText: {
    marginLeft: '16px',
    fontSize: 'var(--rem-24px)',
    fontWeight: '500',
    marginRight: '24px',
  },
  topIconText: {
    fontSize: '14px',
    fontWeight: '500',
    color: 'var(--border-color-lighter)',
  },
  iconBox: {
    marginLeft: 'auto',
  },
  keyvalue: {
    fontSize: 'var(--rem-14px)',
    fontWeight: '500',
    lineHeight: 'var(--rem-20px)',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  keyValueSpan: { color: '#979BA6', fontWeight: '400' },
  keyValueBox: {
    marginTop: 'var(--rem-16px)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
};

const containerStyle: CSSProperties = {
  position: 'relative',
  width: '100vw',
  height: '100vh',
};
