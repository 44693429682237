// src/mapUtils.ts

export const projectPointOntoSegmentRatio = (
  point: google.maps.LatLng,
  start: google.maps.LatLng,
  end: google.maps.LatLng
): number => {
  const dx = end.lng() - start.lng();
  const dy = end.lat() - start.lat();
  const numerator = (point.lng() - start.lng()) * dx + (point.lat() - start.lat()) * dy;
  const denominator = dx * dx + dy * dy;
  return numerator / denominator;
};

export const closestPointOnSegment = (
  point: google.maps.LatLng,
  start: google.maps.LatLng,
  end: google.maps.LatLng
): google.maps.LatLng => {
  const t = Math.max(0, Math.min(1, projectPointOntoSegmentRatio(point, start, end)));
  return new google.maps.LatLng(
    start.lat() + t * (end.lat() - start.lat()),
    start.lng() + t * (end.lng() - start.lng())
  );
};

export const isCoordinateOnLineString = (
  coordinate: google.maps.LatLng,
  lineStringCoords: google.maps.LatLng[],
  tolerance: number = 5
): boolean => {
  for (let i = 0; i < lineStringCoords.length - 1; i++) {
    const start = lineStringCoords[i];
    const end = lineStringCoords[i + 1];

    const distance = google.maps.geometry.spherical.computeDistanceBetween(
      coordinate,
      closestPointOnSegment(coordinate, start, end)
    );

    if (distance <= tolerance) {
      return true;
    }
  }
  return false;
};

export const getStartAndEndPointsFromLineString = (
  coordinates: google.maps.LatLng[]
): [google.maps.LatLng, google.maps.LatLng] => {
  let maxDistance = 0;
  let farthestPoints: [google.maps.LatLng, google.maps.LatLng] = [coordinates[0], coordinates[0]];

  // Compare every pair of points
  for (let i = 0; i < coordinates.length; i++) {
    for (let j = i + 1; j < coordinates.length; j++) {
      const distance = google.maps.geometry.spherical.computeDistanceBetween(coordinates[i], coordinates[j]);

      // If this pair is the farthest so far, update the farthest points
      if (distance > maxDistance) {
        maxDistance = distance;
        farthestPoints = [coordinates[i], coordinates[j]];
      }
    }
  }

  return farthestPoints;
};

export function reorderLatLngByPath(latLngArray: google.maps.LatLng[]): google.maps.LatLng[] {
  // Helper function to calculate the distance between two LatLng points
  function calculateDistance(pointA: google.maps.LatLng, pointB: google.maps.LatLng): number {
    return google.maps.geometry.spherical.computeDistanceBetween(pointA, pointB);
  }

  // Initialize the ordered array with the first point
  const orderedPath: google.maps.LatLng[] = [latLngArray[0]];
  const remainingPoints: google.maps.LatLng[] = latLngArray.slice(1); // Clone array except the first point

  // Iterate to find the nearest point each time until all points are ordered
  while (remainingPoints.length > 0) {
    const lastPoint = orderedPath[orderedPath.length - 1];
    let closestPointIndex = 0;
    let shortestDistance = calculateDistance(lastPoint, remainingPoints[0]);

    // Find the nearest point in the remaining points
    for (let i = 1; i < remainingPoints.length; i++) {
      const distance = calculateDistance(lastPoint, remainingPoints[i]);
      if (distance < shortestDistance) {
        closestPointIndex = i;
        shortestDistance = distance;
      }
    }

    // Add the closest point to the ordered path and remove it from the remaining points
    orderedPath.push(remainingPoints[closestPointIndex]);
    remainingPoints.splice(closestPointIndex, 1);
  }

  return orderedPath;
}

export function convertLatLngArrayToLineString(latLngArray: google.maps.LatLng[]): google.maps.Data.Geometry {
  return new google.maps.Data.LineString(latLngArray);
}
