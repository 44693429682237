import { SVGProps } from 'react';
const TrashIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="none" {...props}>
    <path
      fill={props.color || '#E76262'}
      d="M7.998.953a2 2 0 0 0-2 2h-5a1 1 0 0 0 0 2h14a1 1 0 0 0 0-2h-5a2 2 0 0 0-2-2Zm-7 6v10c0 2.19 1.805 3.97 4 3.97l6.031.03c2.195 0 3.97-1.805 3.97-4v-10H.997Zm5 3a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0v-6a1 1 0 0 1 1-1Zm4 0a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0v-6a1 1 0 0 1 1-1Z"
    />
  </svg>
);
export default TrashIcon;
