import { Box, Tag, TagLabel, TagRightIcon, Text, useOutsideClick } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { JobStatus } from '../shared/const.js';
import { MyIcon } from '../shared/component/Icons.js';

export function StatusTag({
  status,
  isEditing,
  onChangeAction,
}: {
  status: JobStatus;
  isEditing?: boolean;
  onChangeAction?: (status: JobStatus) => void;
}) {
  const [showStatusOption, setShowStatusOption] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<JobStatus>(null);
  const ref = useRef();
  useEffect(() => {
    if (status) {
      setSelectedStatus(status);
    }
  }, [status, isEditing]);
  useOutsideClick({
    ref,
    handler: () => {
      setShowStatusOption(false);
    },
  });
  const getColor = (status: JobStatus) => {
    switch (status) {
      case JobStatus.in_progress:
        return '#ECA336';
      case JobStatus.delayed:
        return '#E76262';
      case JobStatus.completed:
        return '#3ECE80';

      default:
        return 'var(--border-color-lighter)';
    }
  };
  const getBackgroundColor = (status: JobStatus) => {
    switch (status) {
      case JobStatus.in_progress:
        return 'rgba(236, 163, 54, 0.15);';
      case JobStatus.delayed:
        return 'rgba(231, 98, 98, 0.15)';
      case JobStatus.completed:
        return 'rgba(62, 206, 128, 0.15)';
      default:
        return 'rgba(151, 155, 166, 0.15)';
    }
  };
  const getStatusText = (status: JobStatus) => {
    switch (status) {
      case JobStatus.in_progress:
        return 'In Progress';
      case JobStatus.delayed:
        return 'Delayed';
      case JobStatus.completed:
        return 'Completed';

      default:
        return 'Not Started';
    }
  };
  const onSelectStatusFromOptions = (status: JobStatus) => {
    setSelectedStatus(status);
    if (onChangeAction) {
      onChangeAction(status);
    }
  };
  const RightIcon = () => <MyIcon icon="keyboard_arrow_down" style={{ fontSize: '20px', marginLeft: '3px' }} />;
  const onToggleStatusAction = () => {
    setShowStatusOption(!showStatusOption);
  };
  return (
    <Tag
      color={getColor(isEditing ? selectedStatus : status)}
      backgroundColor={getBackgroundColor(isEditing ? selectedStatus : status)}
      borderRadius="24px"
      padding="4px 12px"
      position="relative"
      cursor={isEditing ? 'pointer' : 'auto'}
      onClick={onToggleStatusAction}
    >
      <TagLabel>{getStatusText(isEditing ? selectedStatus : status)}</TagLabel>
      {isEditing && <TagRightIcon as={RightIcon} />}
      {isEditing && showStatusOption && (
        <Box ref={ref} position="absolute" zIndex={1000} top="26px" width="var(--rem-100px)" backgroundColor="#2C2C2C">
          <Box>
            <Text
              onClick={() => onSelectStatusFromOptions(JobStatus.not_started)}
              _hover={{ backgroundColor: 'rgba(151, 155, 166, 0.15)' }}
              style={styles.status}
            >
              Not Started
            </Text>
            <Text
              onClick={() => onSelectStatusFromOptions(JobStatus.in_progress)}
              _hover={{ backgroundColor: 'rgba(151, 155, 166, 0.15)' }}
              style={styles.status}
            >
              In Progress
            </Text>
            <Text
              onClick={() => onSelectStatusFromOptions(JobStatus.delayed)}
              _hover={{ backgroundColor: 'rgba(151, 155, 166, 0.15)' }}
              style={styles.status}
            >
              Delayed
            </Text>
            <Text
              onClick={() => onSelectStatusFromOptions(JobStatus.completed)}
              _hover={{ backgroundColor: 'rgba(151, 155, 166, 0.15)' }}
              style={styles.status}
            >
              Completed
            </Text>
          </Box>
        </Box>
      )}
    </Tag>
  );
}

const styles = {
  status: { color: 'var(--border-color-lighter)', padding: '5px', paddingLeft: '10px' },
};
