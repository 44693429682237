import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Input,
  IconButton,
  useToast,
  Spinner,
  Tag,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import type { LmmImage, LmmRoadAsset } from '../shared/entity.js';
import { MyIcon } from '../shared/component/Icons.js';
import { formatDate } from '../../server/shared/date.helper.js';
import { Zoom } from '../shared/component/Zoom.js';
import { capitalizeEachWord } from '../utils/stringUtils.js';
import { useScreenDimensions } from '../shared/hook/useScreenDimensions.js';
import { AssetStatus } from '../shared/const.js';
import { PCI_COLORS } from '../utils/constants.js';
import { getCategoryKeyFromPci, getColorFromCategoryKey } from './mapHelper.js';
import * as s from './MapLmmImageModal.module.css';

export function MapRoadAssetModal({ lmmRoadAsset, onClose }: { lmmRoadAsset: LmmRoadAsset; onClose(): void }) {
  const toast = useToast();
  const getStatusTags = (status: AssetStatus | string) => {
    switch (status) {
      case AssetStatus.Good:
        return (
          <Tag
            color="#3ECE80"
            backgroundColor="rgba(62, 206, 128, 0.15)"
            borderRadius="24px"
            fontSize="18px"
            padding="4px 12px"
          >
            Good Condition
          </Tag>
        );
      case AssetStatus.Damage:
        return (
          <Tag
            color="#ECA336"
            backgroundColor="rgba(236, 163, 54, 0.15);"
            borderRadius="15px"
            fontSize="18px"
            padding="4px 8px"
          >
            Needs Maintenance
          </Tag>
        );
      default:
        return (
          <Tag
            color="#3ECE80"
            backgroundColor="rgba(62, 206, 128, 0.15)"
            borderRadius="24px"
            fontSize="18px"
            padding="4px 12px"
          >
            Good Condition
          </Tag>
        );
    }
  };

  const { height } = useScreenDimensions();

  return (
    <Modal blockScrollOnMount={false} isOpen onClose={onClose} returnFocusOnClose={false} closeOnEsc={false}>
      <ModalOverlay sx={{ background: 'rgba(0, 0, 0, 0.074)' }} />
      <ModalContent
        bg="var(--bg-color)"
        sx={{ width: '45%', maxWidth: '45%', height: `${height - 130}px`, top: '46px' }}
      >
        <ModalBody className={s.body}>
          <div className={s.header}>
            <div className={s.roadname}>{capitalizeEachWord(lmmRoadAsset.roadName)}</div>
            <div className={s.date}>{formatDate(lmmRoadAsset.captureDate)}</div>
            {/* <div className={s.date}>{lmmRoadAsset}</div> */}
            <ModalCloseButton className={s.close} />
          </div>

          <div style={{ position: 'relative', height: '87%' }}>
            <Zoom
              src={lmmRoadAsset.path}
              alt={80 + ''}
              options={{
                maxScale: 4,
                slider: true,
                zoomer: true,
              }}
            />
            {/* <button onClick={() => loop(-1)} className={s.prev}>
              ❮
            </button>
            <button onClick={() => loop(1)} className={s.next}>
              ❯
            </button> */}
          </div>

          <form>
            <div className={s.pciWrapper} style={{ paddingTop: '10px' }}>
              <div style={{ fontSize: '20px' }}>{lmmRoadAsset.layerName}&nbsp;</div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {getStatusTags(lmmRoadAsset.status)}
                {/* <span
                  style={{
                    color: lmmRoadAsset.status == AssetStatus.Good ? PCI_COLORS.good : PCI_COLORS.poor,
                    textTransform: 'capitalize',
                  }}
                  role="button"
                  tabIndex={-1}
                >
                  {lmmRoadAsset.status == AssetStatus.Good ? 'Good' : 'Defective'}
                </span> */}
              </div>
            </div>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
