import { AssetStatus } from '../shared/const.js';
import type { LmmImage, LmmRoadAsset } from '../shared/entity.js';
import { getColorFromPci } from './mapHelper.js';

export function buildLmmImageMarker(lmmImage: LmmImage, selectedId?: string, opacity: number = 1) {
  const color = getColorFromPci(lmmImage.pci);
  const content =
    /*svg*/ lmmImage.id == selectedId
      ? `
      <svg width="40px" height="40px" viewBox="0 0 512 512" version="1.1" opacity="${opacity}"
          xmlns="http://www.w3.org/2000/svg" fill="${color}">
          <g  stroke-width="0"></g>
          <g stroke-linecap="round" stroke-linejoin="round"></g>
          <g>
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="drop" fill="${color}" transform="translate(42.666667, 42.666667)">
                      <path
                          d="M213.333333,3.55271368e-14 C331.15408,3.55271368e-14 426.666667,95.5125867 426.666667,213.333333 C426.666667,331.15408 331.15408,426.666667 213.333333,426.666667 C95.5125867,426.666667 3.55271368e-14,331.15408 3.55271368e-14,213.333333 C3.55271368e-14,95.5125867 95.5125867,3.55271368e-14 213.333333,3.55271368e-14 Z M213.333333,42.6666667 C119.076736,42.6666667 42.6666667,119.076736 42.6666667,213.333333 C42.6666667,307.589931 119.076736,384 213.333333,384 C307.589931,384 384,307.589931 384,213.333333 C384,119.076736 307.589931,42.6666667 213.333333,42.6666667 Z M213.333333,106.666667 C272.243707,106.666667 320,154.42296 320,213.333333 C320,272.243707 272.243707,320 213.333333,320 C154.42296,320 106.666667,272.243707 106.666667,213.333333 C106.666667,154.42296 154.42296,106.666667 213.333333,106.666667 Z"
                        > </path>
                  </g>
              </g>
          </g>
      </svg>`
      : `
      <svg width="30" height="30" opacity="${opacity}"  viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
        <circle cx="15" cy="15" r="9" fill="${color}"  class="mapMarker"/>
      </svg>
      `;
  return domParser.parseFromString(content, 'image/svg+xml').documentElement;
}

export function buildStopSignMarker(roadSign: LmmRoadAsset, selectedId?: string) {
  const el = document.createElement('img');
  el.style.width = '20px';
  el.style.height = '20px';
  if (roadSign.id == selectedId) {
    el.src =
      roadSign.status == AssetStatus.Good ? '/asset/good_road_sign_active.png' : '/asset/bad_road_sign_active.png';
    el.className = 'signMarker2';
    return el;
  } else {
    el.src = roadSign.status == AssetStatus.Good ? '/asset/good_road_sign.png' : '/asset/bad_road_sign.png';
    el.className = 'signMarker';
    return el;
  }
}

const domParser = new DOMParser();
