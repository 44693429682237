import { Box, Button, IconButton } from '@chakra-ui/react';
import { useState } from 'react';
import PlanIcon from '../asset/icons/PlanIcon.js';
import { ComponentStyle } from '../interface.js';
import { MyIcon, MyIconFilled } from '../shared/component/Icons.js';
import SortDescendIcon from '../asset/icons/SortDescendIcon.js';
import ListIcon from '../asset/icons/ListIcon.js';
import FilterIcon from '../asset/icons/FilterIcon.js';
import MapIcon from '../asset/icons/MapIcon.js';
import { LmmJobResponse } from '../shared/entity.js';
import { CreateJobPanel } from '../map/modals/CreateJobPanel.js';
import { CreateJobModal } from './CreateJobModal.js';

export function TopPageActions({
  refetchJobs,
  onClose,
  job,
  onSwitchToMapView,
}: {
  refetchJobs: () => void;
  onClose: () => void;
  job: LmmJobResponse;
  onSwitchToMapView: () => void;
}) {
  const [openCreateJob, setOpenCreateJob] = useState<boolean>(false);
  return (
    <>
      <CreateJobModal
        onCreateSuccess={refetchJobs}
        onClose={() => {
          onClose();
          setOpenCreateJob(false);
        }}
        open={openCreateJob || job != null}
        job={job}
      />
      {/* {openCreateJob ||
        (job != null && (
          <CreateJobPanel
            onCreateSuccess={refetchJobs}
            onClose={() => {
              onClose();
              setOpenCreateJob(false);
            }}
          />
        ))} */}
      <Box sx={styles.container}>
        <Box sx={styles.sideBox}>
          <PlanIcon />
          <p style={styles.leftText}>All Plans</p>
          <Button
            color="white"
            leftIcon={<MyIcon icon="add" />}
            colorScheme="customBlue"
            onClick={() => setOpenCreateJob(true)}
          >
            Create Job
          </Button>
        </Box>

        <Box sx={styles.sideBox}>
          {/* <Button
            color="white"
            leftIcon={<MyIcon icon="add" />}
            colorScheme="customBlue"
            onClick={() => setOpenCreateJob(true)}
          >
            Create Job
          </Button> */}
          {/* <Box sx={styles.iconBox}>
            <p style={styles.topIconText}>Filter</p>
            <IconButton
              title="Filter Jobs"
              aria-label="Filter Jobs"
              icon={<FilterIcon width={20} height={20} />}
              size="lg"
              background="var(--separator-color)"
              height="44px"
              width="44px"
            />
          </Box>
          <Box sx={styles.iconBox}>
            <p style={styles.topIconText}>Sort</p>
            <IconButton
              title="Sort Jobs"
              aria-label="Sort Jobs"
              icon={<SortDescendIcon width={20} height={20} />}
              size="lg"
              background="var(--separator-color)"
              height="44px"
              width="44px"
            />
          </Box> */}
          <Box sx={styles.iconBox}>
            <p style={styles.topIconText}>View</p>
            <Box sx={{ backgroundColor: '#2C2C2C', border: '1.5px solid #383839', borderRadius: '8px' }}>
              <IconButton
                title="List View"
                aria-label="List View"
                icon={<ListIcon width={20} height={20} />}
                size="lg"
                colorScheme="customBlue"
                height="var(--rem-44px)"
                width="var(--rem-44px)"
              />
              <IconButton
                title="Map View"
                aria-label="Map View"
                icon={<MapIcon color="var(--border-color-lighter)" />}
                size="lg"
                color="none"
                background="none"
                height="var(--rem-44px)"
                width="var(--rem-44px)"
                onClick={onSwitchToMapView}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

const styles: ComponentStyle = {
  container: {
    marginTop: '120px',
    marginLeft: '32px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  sideBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  leftText: {
    marginLeft: '16px',
    fontSize: '20px',
    fontWeight: '500',
    marginRight: '24px',
  },
  topIconText: {
    fontSize: '14px',
    fontWeight: '500',
    color: 'var(--border-color-lighter)',
  },
  iconBox: {
    marginLeft: '6px',
    marginRight: '6px',
  },
};
