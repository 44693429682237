import { SVGProps } from 'react';
const ListIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill={props.color || '#fff'}
      d="M2.5 5.086a.834.834 0 1 0 0 1.667.834.834 0 0 0 0-1.667Zm3.333 0a.834.834 0 0 0 0 1.667h11.666a.834.834 0 0 0 0-1.667H5.833ZM2.499 9.253A.834.834 0 1 0 2.5 10.92a.834.834 0 0 0 0-1.667Zm3.334 0a.834.834 0 0 0 0 1.666h11.666a.834.834 0 0 0 0-1.666H5.833Zm-3.334 4.166a.834.834 0 1 0 0 1.668.834.834 0 0 0 0-1.668Zm3.334 0a.834.834 0 0 0 0 1.667h11.666a.834.834 0 0 0 0-1.667H5.833Z"
    />
  </svg>
);
export default ListIcon;
