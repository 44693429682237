const DISPATCH_ACTIONS = {
  SET_LMMIMAGES: 'setLmmImages',
  SET_LMMROADS: 'setLmmRoads',
  SET_IMAGESINVIEW: 'setImagesInView',
  SET_TOPNAVPLACEHOLDAER: 'setTopNavPlaceholder',
  SET_LMMJOBS: 'setLmmJobs',
  SET_LMMSECTIONS: 'setLmmSections',
  SET_CURRENT_ROAD_JOBS: 'setCurrentRoadJobs',
  SET_IS_CREATING_JOB: 'setIsCreatingJob',
  SET_IS_UPDATING_PLAN_MAP_JOB: 'updatePlanMapViewJob',
  SET_ROAD_TO_SELECT_MILEPOST: 'setRoadToSelectMilePost',
  SET_MILEPOST: 'setMilepost',
  SET_CURRENTLINE_DATA: 'setCurrentLineData',
  SET_TREATMENTS: 'setTreatment',
  SET_COMPANY_USERS: 'setCompanyUsers',
  UPDATE_JOB_DATA: 'updateJobData',
};

export default DISPATCH_ACTIONS;
