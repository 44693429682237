import { SVGProps } from 'react';
const EditIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill={props.color || '#979BA6'}
      d="M16.998 1.953a1.02 1.02 0 0 0-.719.281l-2 2-7 7a1.02 1.02 0 0 0-.28.72v4a1 1 0 0 0 1 1h4c.264 0 .53-.095.718-.282l7-7 2-2a1.02 1.02 0 0 0 .281-.719c0-1.636-.417-2.805-1.28-3.688-.874-.89-2.039-1.312-3.72-1.312Zm-10 1a4 4 0 0 0-4 4v10a4 4 0 0 0 4 4h10a4 4 0 0 0 4-4v-4a1 1 0 0 0-2 0v4a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2v-10a2 2 0 0 1 2-2h4a1 1 0 0 0 0-2h-4Zm10.407 1.02c.902.052 1.473.258 1.874.668.41.418.646 1.05.701 1.934-.489.49-.792.78-.978.966-.793-.792-1.799-1.789-2.59-2.582.186-.187.504-.498.993-.987ZM15 6.378l2.585 2.578-6.025 5.996H8.998v-2.562L15 6.38Z"
      opacity={0.987}
    />
  </svg>
);
export default EditIcon;
