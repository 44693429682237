export const SENTRY_DNS =
  'https://a2c4143cea7f74dfa9c2bfbd604769d4@o4507590129876992.ingest.us.sentry.io/4507590146260992';

export const PCI_COLORS = {
  excellent: '#4A90E2',
  good: '#3ece80',
  fair: '#eca336',
  poor: '#e76262',
};

export const APP_COLORS = {
  blue: '#4A90E2',
  green: '#3ece80',
  yellow: '#eca336',
  red: '#e76262',
  gray: '#979BA6',
};
