import { DefaultRenderer } from '@googlemaps/markerclusterer';

export default class CustomRenderer extends DefaultRenderer {
  override render({ count, position }: any, stats: any, map: google.maps.Map) {
    let size = 25; // Default small size
    if (count > 99) {
      size = 30;
    }

    // Customize color based on number of markers in the cluster
    const color = '#979BA6';

    const contentDiv = document.createElement('div');
    contentDiv.style.backgroundColor = color;
    contentDiv.style.width = `${size}px`;
    contentDiv.style.height = `${size}px`;
    contentDiv.style.borderRadius = '50%';
    contentDiv.style.display = 'flex';
    contentDiv.style.justifyContent = 'center';
    contentDiv.style.alignItems = 'center';
    contentDiv.style.color = 'white';
    // contentDiv.style.fontWeight = 'bold';
    contentDiv.style.fontSize = '12px';
    contentDiv.innerText = String(count);

    contentDiv.style.boxShadow = `0 0 ${size / 2}px rgba(151, 155, 166, 0.7)`;
    contentDiv.style.border = `1px solid rgba(255, 255, 255, 0.5)`;

    contentDiv.style.backgroundImage = `radial-gradient(circle, rgba(151, 155, 166, 1) 50%, rgba(151, 155, 166, 0.5) 100%)`;

    return new google.maps.marker.AdvancedMarkerElement({
      position,
      content: contentDiv,
      map,
    });
  }
}
