/* eslint-disable complexity */
import { table } from 'console';
import {
  Box,
  IconButton,
  Tag,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from '@chakra-ui/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { container } from 'webpack';
import { useQuery } from 'react-query';
import { ComponentStyle } from '../../interface.js';
import { MyIcon } from '../../shared/component/Icons.js';
import { useScreenDimensions } from '../../shared/hook/useScreenDimensions.js';
import { LmmImage, LmmJob, LmmRoad } from '../../shared/entity.js';
import { capitalizeEachWord, formatAsShortenedCurrency, getYearDate } from '../../utils/stringUtils.js';
import { PCI_COLORS } from '../../utils/constants.js';
import { getCategoryKeyFromPci, getColorFromPci } from '../mapHelper.js';
import { adjustCentermile, round } from '../../../server/shared/number.helper.js';
import { StatusTag } from '../../plan/StatusTag.js';
import { JobStatus } from '../../shared/const.js';
import { GlobalContext } from '../../context/GlobalContext.js';
import { CreateJobModal } from '../../plan/CreateJobModal.js';
import DISPATCH_ACTIONS from '../../context/actions.js';
import { CreateJobPanel } from './CreateJobPanel.js';

export function RoadInfoModal({ onClose, road, lmmImages }: { onClose(): void; road: LmmRoad; lmmImages: LmmImage[] }) {
  const { height } = useScreenDimensions();
  const { state, dispatch } = useContext(GlobalContext);

  const average = useRef<number>(0);
  const [isShrunk, setIsShrunk] = useState(false);

  const { refetch: refetchJobs } = useQuery<LmmJob[]>(`/api/jobs/road/${road.id}`, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
    async onSuccess(jobs) {
      dispatch({ type: DISPATCH_ACTIONS.SET_CURRENT_ROAD_JOBS, payload: jobs });
    },
  });

  useEffect(() => {
    if (road) {
      const roadImages = lmmImages.filter((x) => x.roadId == road.id);
      let excellent = 0;
      let good = 0;
      let fair = 0;
      let poor = 0;
      const imagesLength = roadImages?.length;
      if (roadImages.length) {
        let totalPci = 0;
        for (const image of roadImages) {
          if (!image.pci) continue;
          totalPci += image.pci;
          const pciColor = getColorFromPci(image.pci);
          if (pciColor == PCI_COLORS.excellent) excellent++;
          else if (pciColor == PCI_COLORS.good) good++;
          else if (pciColor == PCI_COLORS.fair) fair++;
          else poor++;
        }
        average.current = round(totalPci / imagesLength);
      }
    }
  }, [lmmImages, road]);

  useEffect(() => {
    setIsShrunk(false);
  }, [road]);

  const handleShrink = () => {
    setIsShrunk((prev) => !prev);
  };

  const getPciCategoryTags = (pci: number) => {
    const categoryKey = getCategoryKeyFromPci(pci);
    switch (categoryKey) {
      case 'excellent':
        return (
          <Tag color={PCI_COLORS.excellent} backgroundColor="#4a91e227" borderRadius="24px" padding="4px 12px">
            Excellent
          </Tag>
        );
      case 'good':
        return (
          <Tag color={PCI_COLORS.good} backgroundColor="gba(62, 206, 128, 0.15)" borderRadius="24px" padding="4px 12px">
            Good
          </Tag>
        );
      case 'fair':
        return (
          <Tag
            color={PCI_COLORS.fair}
            backgroundColor="rgba(236, 163, 54, 0.15);"
            borderRadius="24px"
            padding="4px 12px"
          >
            Fair
          </Tag>
        );

      default:
        return (
          <Tag color={PCI_COLORS.poor} backgroundColor="rgba(231, 98, 98, 0.15)" borderRadius="24px" padding="4px 12px">
            Poor
          </Tag>
        );
    }
  };

  const handleShowCreateJob = (show: boolean) => {
    dispatch({ type: DISPATCH_ACTIONS.SET_IS_CREATING_JOB, payload: show });
  };

  return (
    <>
      {state.isCreatingJob && (
        <CreateJobPanel
          onCreateSuccess={refetchJobs}
          onClose={() => handleShowCreateJob(false)}
          road={road}
          onSelectMilePost={setIsShrunk}
        />
      )}
      <Box
        bg="var(--bg-color)"
        sx={{
          maxHeight: `${height - 130}px`,
          width: isShrunk ? '150px' : 'var(--rem-500px)',
          padding: isShrunk ? '10px' : '22px 24px 10px 24px',
          ...(isShrunk && { display: 'flex', flexDirection: 'row', alignItems: 'center' }),
          ...styles.container,
        }}
      >
        <IconButton
          title={isShrunk ? 'Maximize' : 'Minimize'}
          aria-label={isShrunk ? 'Maximize' : 'Minimize'}
          icon={
            <MyIcon icon={isShrunk ? 'arrow_back' : 'arrow_right_alt'} style={{ color: '#fff', fontWeight: '600' }} />
          }
          size="md"
          onClick={handleShrink}
          sx={{
            backgroundColor: '#464647',
            position: isShrunk ? 'relative' : 'absolute',
            height: '40px',
            width: '40px',
            borderRadius: '50%',
            left: isShrunk ? 'auto' : '-20px',
            marginRight: isShrunk ? '10px' : 'auto',
            '&:hover': {
              backgroundColor: '#5a5a5b',
            },
          }}
        />
        {isShrunk ? (
          <p style={{ fontSize: '18px', fontWeight: '500' }}>Road Info</p>
        ) : (
          <>
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
              <p style={{ fontSize: 'var(--rem-24px)', fontWeight: '700', lineHeight: 'var(--rem-32px)' }}>
                {capitalizeEachWord(road?.name)}
              </p>

              {(road?.average || average.current) && getPciCategoryTags(road?.average || average.current)}
            </Box>
            <Box
              backgroundColor="var(--separator-color)"
              height="1px"
              width="100%"
              mt="var(--rem-16px)"
              mb="var(--rem-24px)"
            ></Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <Box sx={{ ...styles.labelContainer, width: '60%' }}>
                <p style={styles.infoLabel}>
                  Pavement Condition Index:
                  <span style={styles.infoValue}>{road?.average || average.current}</span>
                </p>
              </Box>
              {((road.centerline !== null && road.centerline !== undefined && road.centerline !== 0) ||
                state.currentLineData.centerline) && (
                <Box sx={{ ...styles.labelContainer, width: '40%' }} textAlign="right">
                  <p style={styles.infoLabel}>
                    Centerline Miles:
                    <span style={styles.infoValue}>{adjustCentermile(state.currentLineData.centerline)}</span>
                  </p>
                </Box>
              )}
              {/* {false && ( */}
              <Box sx={styles.labelContainer}>
                <p style={styles.infoLabel}>
                  Material:
                  <span style={styles.infoValue}>Asphalt</span>
                </p>
              </Box>
              {/* )} */}
              {road.lane !== null && road.lane !== undefined && road.lane !== 0 && (
                <Box sx={styles.labelContainer}>
                  <p style={styles.infoLabel}>
                    Lane Miles:
                    <span style={styles.infoValue}>12.6</span>
                  </p>
                </Box>
              )}
              {false && (
                <Box sx={styles.labelContainer}>
                  <p style={styles.infoLabel}>
                    Road Width:
                    <span style={styles.infoValue}>22 feet</span>
                  </p>
                </Box>
              )}
            </Box>

            <Accordion defaultIndex={[0]} allowMultiple p="0px">
              <AccordionItem border="none">
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" mt="16px">
                  <AccordionButton p="0px" m="0px" width="50%">
                    <AccordionIcon color="var(--border-color-lighter)" marginRight="16px" />
                    <p style={{ fontSize: '20px', fontWeight: '500', lineHeight: '28px' }}>Jobs</p>
                  </AccordionButton>
                  <Button
                    color="white"
                    leftIcon={<MyIcon icon="add" />}
                    colorScheme="customBlue"
                    onClick={() => handleShowCreateJob(true)}
                    isDisabled={state.isCreatingJob}
                  >
                    Create Job
                  </Button>
                </Box>
                <Box backgroundColor="var(--separator-color)" height="1px" width="100%" mt="16px"></Box>

                <AccordionPanel p={0}>
                  <TableContainer mt="16px">
                    <Table variant="simple" size="sm">
                      <Thead>
                        <Tr>
                          <Th sx={styles.tableHead}>TREATMENT</Th>
                          <Th sx={styles.tableHead} textAlign="right">
                            COST
                          </Th>
                          <Th sx={styles.tableHead} textAlign="right">
                            PLANNED DATE
                          </Th>
                          <Th sx={styles.tableHead}>STATUS</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {state.currentRoadJobs.length == 0 ? (
                          <></>
                        ) : (
                          state.currentRoadJobs.map((job, index) => (
                            <Tr key={index}>
                              <Td sx={styles.tableRow}>{job.treatmentName}</Td>
                              <Td sx={styles.tableRow} textAlign="right">
                                {formatAsShortenedCurrency(job.estimatedCost)}
                              </Td>
                              <Td sx={styles.tableRow} textAlign="right">
                                {getYearDate(job.plannedDate)}
                              </Td>
                              <Td sx={styles.tableRow}>
                                <StatusTag status={job.status} />
                              </Td>
                            </Tr>
                          ))
                        )}
                      </Tbody>
                    </Table>
                  </TableContainer>
                  {state.currentRoadJobs.length == 0 && (
                    <Box display="flex" justifyContent="center" mt="15px">
                      <i style={{ color: 'var(--Color-Lighter-Grey)' }}>No job has been created on this road</i>
                    </Box>
                  )}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </>
        )}
      </Box>
    </>
  );
}

const styles: ComponentStyle = {
  container: {
    top: '172px',
    marginLeft: '0px',
    marginRight: 'var(---view-padding-m)',
    position: 'fixed',
    right: 0,
    borderRadius: '24px',
    transition: 'width 0.5s ease',
  },
  labelContainer: { width: '50%', marginBottom: '8px' },
  infoLabel: {
    color: 'var(--Color-Lighter-Grey)',
    fontSize: 'var(--rem-16px)',
    fontWeight: '400',
    lineHeight: 'var(--rem-24px)',
  },
  infoValue: { color: '#fff', fontWeight: '500', marginLeft: '7px' },
  tableHead: { borderWidth: '0px' },
  tableRow: {
    fontSize: 'var(--rem-14px)',
    lineHeight: 'var(--rem-24px)',
    borderWidth: '0px',
  },
};
